import { Injectable } from '@angular/core';

import { Observable, Subject, from, throwError, forkJoin } from 'rxjs';

import { map, finalize } from 'rxjs/operators';


import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import {
    AngularFirestore,
    AngularFirestoreDocument,
    DocumentData,
    AngularFirestoreCollection
} from '@angular/fire/firestore';

import { User } from './../modules/user/user';
import { UserService } from '../modules/user/user.service';

export interface UploadStatus {
    filename: string;
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class DataService {

    user: User = null;
    usersCollectionName = 'users';

    private _upload: Subject<UploadStatus> = new Subject<UploadStatus>();
    upload$: Observable<UploadStatus> = this._upload.asObservable();

    constructor(
        private userService: UserService,
        private afs: AngularFirestore,
        private storage: AngularFireStorage) {

        userService.user$.subscribe(user => this.user = user);
    }

    private getUserDoc(): AngularFirestoreDocument<DocumentData> {
        if (!this.user) {
            throw Error('Cannot read data, user is not authenticated.');
        }

        return this.afs
            .collection(this.usersCollectionName)
            .doc(this.user.uid);
    }

    listenAll(collection: string, query?: any): Observable<DocumentData[]> {

        try {
            let dataset: AngularFirestoreCollection;
            if (query) {
                dataset = this.getUserDoc()
                    .collection(collection, ref => ref.where('ngrams', 'array-contains', query));
            } else {
                dataset = this.getUserDoc()
                    .collection(collection);
            }

            return dataset
                .snapshotChanges()
                .pipe(
                    map(
                        actions => actions.map(
                            action => {
                                if (action.type === 'added' || action.type === 'modified') {
                                    return { ...action.payload.doc.data(), id: action.payload.doc.ref.id };
                                }
                            }
                        )
                    )
                );
        } catch (e) {
            return throwError(e);
        }
    }

    createDoc(collection: string, data: any): Observable<any> {
        try {
            return from(this.getUserDoc()
                .collection(collection)
                .add(data));
        } catch (e) {
            return throwError(e);
        }
    }

    deleteDoc(collection: string, doc: string): Observable<any> {
        try {
            return from(this.getUserDoc()
                .collection(collection)
                .doc(doc)
                .delete()
            );
        } catch (e) {
            return throwError(e);
        }
    }

    read(collection: string, document: string): Observable<any> {
        try {
            return this.getUserDoc()
                .collection(collection)
                .doc(document)
                .get()
                .pipe(map(docRef => (docRef.exists ? { ...docRef.data(), id: docRef.ref.id } : null)));
        } catch (e) {
            return throwError(e);
        }
    }

    uploadFiles(files: FileList, path: string) {

        Array.from(files).forEach(file => {

            const filePath = `${this.user.uid}/${path}/${file.name}`;
            const fileRef = this.storage.ref(filePath);

            const task: AngularFireUploadTask = this.storage.upload(filePath, file);

            task.snapshotChanges()
                .pipe(
                    finalize(() => fileRef.getDownloadURL()
                        .subscribe(
                            url =>
                                this._upload.next({
                                    filename: file.name,
                                    url
                                })
                        )
                    )
                )
                .subscribe();
        });
    }

    getFileUrls(refs: any): Observable<string[]> {
        const urls$ = [];

        refs.forEach((ref: string) => {
            urls$.push(this.storage.ref(ref).getDownloadURL());
        });

        return forkJoin(urls$);
    }
}
